import styles from './authorization.module.scss'
import {SelectCountries} from "../../components/selectCountries/selectCountries";
import {useState} from "react";
import {apiRequests} from "../../shared/api/apiRequests";
import Cookies from "js-cookie";
import {setUserData} from "../../shared/store/main";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import passwordIco from '../../shared/images/password.svg'
import {allTimeZone} from './constants';

const phoneReg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

export const AuthorizationPage = () => {
    const [country, setCountry] = useState(allTimeZone[185])
    const [numberValue, setNumberValue] = useState('')
    const [password, setPassword] = useState('')
    const [isVisiblePass, setVisible] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChange = (value) => {
        if (phoneReg.test(value) || value === '') {
            setNumberValue(value)
        }
    }

    const handleSubmit = async () => {
        const number = `${country.MobileCode.replace('+', '')}${numberValue}`
        await apiRequests.user.authorization({
            phone: number,
            password
        })
            .then((res) => {
                Cookies.set('accessToken', res.data.access_token)
                Cookies.set('refreshToken', res.data.refresh_token)

                apiRequests.user.getData()
                    .then((res) => {
                        dispatch(setUserData(res.data))
                        navigate('/profile')
                    })
            })
            .catch((e) => {
                if (e.response.data.detail === "Incorrect Email or Password") {
                    alert('Неправильный номер или пароль')
                }
            })
    }

    return (
        <div>
            <div className="mobile-container">
                <header className="mobile-header">
                    <h4>Войдите в аккаунт</h4>
                </header>

                <div className={styles.wrapper}>
                    <div className={styles.form}>
                        <SelectCountries country={country} setCountry={setCountry} />
                        <div className={styles.inputBody}>
                            <input
                                value={numberValue}
                                onChange={(e) => handleChange(e.target.value)}
                                type="text"
                                placeholder={'Номер телефона'}
                            />
                        </div>
                    </div>
                    <div className={styles.passBody}>
                        <input
                            value={password}
                            placeholder={'Введите пароль'}
                            onChange={(e) => setPassword(e.target.value)}
                            type={isVisiblePass ? 'text' : 'password'}
                            className={styles.inputPassword}
                        />
                        <button onClick={() => setVisible(prev => !prev)}>
                            <img src={passwordIco} alt=""/>
                        </button>
                    </div>

                    <Link className={styles.resetPassword} to={'/reset_password'}>
                        Сбросить пароль
                    </Link>
                </div>

                <button onClick={handleSubmit} className={'green-button'}>Войти</button>
            </div>
        </div>
    )
}