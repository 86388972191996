// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__MhatS {
  margin-top: 26px;
}

.header_wrapper__kEa-l {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_wrapper__kEa-l nav {
  display: flex;
  align-items: center;
  grid-gap: 20px;
}
.header_wrapper__kEa-l nav a {
  font-family: "Inter", sans-serif;
  color: #666666;
  text-decoration: none;
  font-size: 16px;
}

.header_left__qekvE {
  display: flex;
  align-items: center;
  grid-gap: 60px;
}

.header_telegram__SZ9FJ {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  color: #000;
}

@media (max-width: 430px) {
  .header_wrapper__kEa-l {
    justify-content: space-between;
  }
  .header_left__qekvE img {
    display: none;
  }
  .header_left__qekvE nav {
    grid-gap: 10px;
  }
  .header_telegram__SZ9FJ p {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;AACE;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AACJ;AACI;EACE,gCAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;AACN;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AADF;;AAIA;EACE,gCAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;AADF;;AAIA;EACE;IACE,8BAAA;EADF;EAIA;IACE,aAAA;EAFF;EAKA;IACE,cAAA;EAHF;EAME;IACE,aAAA;EAJJ;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap');\r\n\r\n.header {\r\n  margin-top: 26px;\r\n}\r\n\r\n.wrapper {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  nav {\r\n    display: flex;\r\n    align-items: center;\r\n    grid-gap: 20px;\r\n\r\n    a {\r\n      font-family: \"Inter\", sans-serif;\r\n      color: #666666;\r\n      text-decoration: none;\r\n      font-size: 16px;\r\n    }\r\n  }\r\n}\r\n\r\n.left {\r\n  display: flex;\r\n  align-items: center;\r\n  grid-gap: 60px;\r\n}\r\n\r\n.telegram {\r\n  font-family: \"Inter\", sans-serif;\r\n  text-decoration: none;\r\n  display: flex;\r\n  align-items: center;\r\n  grid-gap: 10px;\r\n  color: #000;\r\n}\r\n\r\n@media (max-width: 430px) {\r\n  .wrapper {\r\n    justify-content: space-between;\r\n  }\r\n\r\n  .left img {\r\n    display: none;\r\n  }\r\n\r\n  .left nav {\r\n    grid-gap: 10px;\r\n  }\r\n  .telegram {\r\n    p {\r\n      display: none;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__MhatS`,
	"wrapper": `header_wrapper__kEa-l`,
	"left": `header_left__qekvE`,
	"telegram": `header_telegram__SZ9FJ`
};
export default ___CSS_LOADER_EXPORT___;
