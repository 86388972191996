// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../shared/font/gteestiprodisplay_regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../shared/font/gteestiprodisplay_bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../shared/font/gteestiprodisplay_light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "GteDisplayBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "GteDisplayExtra";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "GteDisplayLight";
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: normal;
}
.historyOfTrip_wrapper__euuno {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 7px;
}

.historyOfTrip_card__sBTZ1 h4 {
  font-size: 18px;
  font-family: GteDisplayBold;
}
.historyOfTrip_card__sBTZ1 .historyOfTrip_cardWrapper__ywXjk {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
}
.historyOfTrip_card__sBTZ1 .historyOfTrip_cardWrapper__ywXjk span {
  font-family: GteDisplayLight;
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/historyOfTrip/historyOfTrip.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,+DAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACA;EACE,8BAAA;EACA,+DAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACA;EACE,8BAAA;EACA,kBAAA;EACA,+DAAA;EACA,mBAAA;AACF;AAGA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AADF;;AAIE;EACE,eAAA;EACA,2BAAA;AADJ;AAGE;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AADJ;AAEI;EACE,4BAAA;EACA,eAAA;AAAN","sourcesContent":["@font-face {\r\n  font-family: \"GteDisplayBold\";\r\n  src: url('../../shared/font/gteestiprodisplay_regular.otf') format(\"truetype\");\r\n  font-style: normal;\r\n  font-weight: normal;\r\n}\r\n@font-face {\r\n  font-family: \"GteDisplayExtra\";\r\n  src: url('../../shared/font/gteestiprodisplay_bold.otf') format(\"truetype\");\r\n  font-style: normal;\r\n  font-weight: normal;\r\n}\r\n@font-face {\r\n  font-family: \"GteDisplayLight\";\r\n  font-style: normal;\r\n  src: url('../../shared/font/gteestiprodisplay_light.otf') format(\"truetype\");\r\n  font-weight: normal;\r\n}\r\n\r\n\r\n.wrapper {\r\n  margin-top: 20px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  grid-gap: 7px;\r\n}\r\n.card {\r\n  h4 {\r\n    font-size: 18px;\r\n    font-family: GteDisplayBold;\r\n  }\r\n  .cardWrapper {\r\n    margin-top: 5px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    grid-gap: 4px;\r\n    span {\r\n      font-family: GteDisplayLight;\r\n      font-size: 15px;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `historyOfTrip_wrapper__euuno`,
	"card": `historyOfTrip_card__sBTZ1`,
	"cardWrapper": `historyOfTrip_cardWrapper__ywXjk`
};
export default ___CSS_LOADER_EXPORT___;
