// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setNewPassword_wrapper__Adtwv div {
  margin: 50px 0 100px 0;
}
.setNewPassword_wrapper__Adtwv input {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/setNewPassword/setNewPassword.module.scss"],"names":[],"mappings":"AACI;EACI,sBAAA;AAAR;AAEI;EACI,WAAA;AAAR","sourcesContent":[".wrapper {\r\n    div {\r\n        margin: 50px 0 100px 0;\r\n    }\r\n    input {\r\n        width: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `setNewPassword_wrapper__Adtwv`
};
export default ___CSS_LOADER_EXPORT___;
