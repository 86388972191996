// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editPassword_form__MriKe {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  margin-bottom: 100px;
}
.editPassword_form__MriKe button {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(0, -50%);
}
.editPassword_form__MriKe input {
  width: 100%;
  display: block;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/editPassword/editPassword.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,oBAAA;AADF;AAEE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,6BAAA;AAAJ;AAEE;EACE,WAAA;EACA,cAAA;EACA,aAAA;AAAJ","sourcesContent":["\r\n\r\n.form {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  grid-gap: 10px;\r\n  margin-bottom: 100px;\r\n  button {\r\n    position: absolute;\r\n    top: 50%;\r\n    right: 10%;\r\n    transform: translate(0, -50%);\r\n  }\r\n  input {\r\n    width: 100%;\r\n    display: block;\r\n    padding: 20px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `editPassword_form__MriKe`
};
export default ___CSS_LOADER_EXPORT___;
