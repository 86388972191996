import styles from './companies.module.scss'


export const Companies = () => {
    return (
        <div className={styles.body}>
            <div className="container">
                <div className={styles.wrapper}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>

                </div>
            </div>
        </div>
    )
}