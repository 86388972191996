import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import './successWindow.scss';

import { setUserData } from '../../shared/store/main';
import { apiRequests } from '../../shared/api/apiRequests';
import success from '../../shared/images/payment_success.svg';
import error from '../../shared/images/payment_error.svg';
// import pending from '../../shared/images/payment_pending.svg';

const SuccessPaymentPage = () => {
    const location = useLocation();
    const { user } = useSelector((state) => state.mainSlice);

    // Извлекаем transaction_id из строки запроса
    const { transaction_id } = queryString.parse(location.search);

    // console.log(transaction_id);
    // const navigate = useNavigate();

    const dispatch = useDispatch();
    const [paymentStatus, setPaymentStatus] = useState('pending');

    useEffect(() => {
        const checkPaymentStatus = async () => {
            await apiRequests.user
                .checkPaymentStatus(transaction_id)
                .then((res) => {
                    if (res.data.status === 'COMPLETE') {
                        setPaymentStatus('success');
                        dispatch(
                            setUserData({
                                ...user,
                                is_premium: true,
                                is_trial_perm: false,
                            }),
                        );
                    } else if (res.data.status === 'FAILED') {
                        setPaymentStatus('error');
                    }
                    // navigate('/payment/status', {state: {status: 'success'}})
                })
                .catch(() => {
                    console.error('error check status');
                });
        };

        const intervalId = setInterval(checkPaymentStatus, 3000);

        return () => clearInterval(intervalId);
    }, [transaction_id]);

    if (paymentStatus === 'pending') {
        return (
            <div className="success-screen">
                <div className="purchase">Покупка</div>
                <div className="success-icon">
                    {/* <img src={pending} alt="" /> */}
                </div>
                <div className="success-text">Ожидание подтверждения</div>
                <div className="subtitle">
                    Пожалуйста, не закрывайте страницу
                </div>
            </div>
        );
    }

    if (paymentStatus === 'success') {
        return (
            <div className="success-screen">
                <div className="purchase">Покупка</div>
                <div className="success-icon">
                    <img src={success} alt="" />
                </div>
                <div className="success-text">Успешно</div>
                <div className="subtitle">
                    Пользуйтесь премиумом на здоровье!
                </div>
                <Link className="green-button" to={'/profile'}>
                    Готово
                </Link>
            </div>
        );
    }

    if (paymentStatus === 'error') {
        return (
            <div className="success-screen">
                <div className="purchase">Покупка</div>
                <div className="success-icon">
                    <img src={error} alt="" />
                </div>
                <div className="success-text">Ошибка</div>
                <div className="subtitle">Произошла ошибка</div>
                <Link className="green-button" to={'/profile'}>
                    На главную
                </Link>
            </div>
        );
    }
};

export default SuccessPaymentPage;
