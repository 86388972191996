// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companies_body__AsKJw {
  margin-top: 100px;
}

.companies_wrapper__gQ-DI {
  padding: 32px 0;
  border-top: 1px solid #E5E7EB;
  border-bottom: 1px solid #E5E7EB;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 64px;
}
.companies_wrapper__gQ-DI span {
  background: gray;
  width: 65px;
  height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/companies/companies.module.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF;;AAGA;EACE,eAAA;EACA,6BAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAAF;AAEE;EACE,gBAAA;EACA,WAAA;EACA,YAAA;AAAJ","sourcesContent":["\r\n.body {\r\n  margin-top: 100px;\r\n}\r\n\r\n.wrapper {\r\n  padding: 32px 0;\r\n  border-top: 1px solid #E5E7EB;\r\n  border-bottom: 1px solid #E5E7EB;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  grid-gap: 64px;\r\n\r\n  span {\r\n    background: gray;\r\n    width: 65px;\r\n    height: 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `companies_body__AsKJw`,
	"wrapper": `companies_wrapper__gQ-DI`
};
export default ___CSS_LOADER_EXPORT___;
