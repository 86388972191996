import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import styles from './advertisementBanner.module.scss';
import { Link } from 'react-router-dom';
import { setUserData } from '../../shared/store/main';

import { apiRequests } from '../../shared/api/apiRequests';
import img from '../../shared/images/ad_banner.jpg';
import close from '../../shared/images/close.svg';

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

export const AdvertisementBanner = ({ setAdvertisement }) => {
    // Получаем текущую дату
    const today = new Date();
    // Получаем дату через неделю
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 7);
    // Дата на неделю вперёд, что бы отобразить в профиле без обновления юзера
    const neewWeekDate = formatDate(nextWeek);

    const { user, subPrice } = useSelector((state) => state.mainSlice);

    const dispatch = useDispatch();
    // console.log(user)

    const accessToken = Cookies.get('accessToken');

    const redirectLink = `${process.env.REACT_APP_URL}/payment?token=${accessToken}`;

    const handleSubmit = async () => {
        await apiRequests.payment
            .activateTrial()
            .then((res) => {
                dispatch(
                    setUserData({
                        ...user,
                        is_premium: true,
                        is_trial_perm: false,
                        sub_end_date: neewWeekDate,
                    }),
                );
                setAdvertisement(false);
                // navigate('/payment/status', {state: {status: 'success'}})
            })
            .catch(() => {
                console.error('error activate trial');
                // navigate('/payment/status', {state: {status: 'error'}})
            });
    };

    return (
        <div className={styles.body}>
            <img src={img} alt="" />
            <button
                onClick={() => setAdvertisement(false)}
                className={styles.closeBtn}
            >
                <img src={close} alt="" />
            </button>
            <div className="mobile-container">
                <div className={styles.header}>
                    <h3>
                        Попробуйте <span>Golden Whells</span>
                    </h3>
                    <p>Члены GOLDEN WHEELS на 70% чаще достигают своих целей</p>
                </div>

                <div className={styles.wrapper}>
                    <div className={styles.item}>
                        <p>Открыть доступ к геопозиции другим пользователям</p>
                    </div>
                    <div className={styles.item}>
                        <p>
                            Производить обмен сообщениями с другими
                            пользователями которые купили Golden Whells
                        </p>
                    </div>
                    <div className={styles.item}>
                        <p>100% без рекламы</p>
                    </div>
                </div>

                <div className={styles.actions}>
                    <div>
                        Подписка ежемесячно - ₽{subPrice}
                        {',00'}
                    </div>
                    {user.is_trial_perm ? (
                        <button
                            onClick={handleSubmit}
                            className={'green-button'}
                        >
                            Попробуйте бесплатно
                        </button>
                    ) : (
                        // <Link to={redirectLink} className={'green-button'}>
                        //     Перейти к оплате
                        // </Link>
                        <a
                            href={redirectLink}
                            // target="_blank"
                            // rel="noopener noreferrer"
                            className={'green-button'}
                        >
                            Перейти к оплате
                        </a>
                    )}
                </div>
                {user.is_trial_perm && (
                    <div className={styles.info}>
                        <p>
                            Попробовать 7 дней бесплатно <br />
                            {/* <span>Затем ₽10 290,00 в год</span><br/>
                            Отменить подписку можно в любое время. */}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};
